import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import Image from "../../../components/image"
import SEO from "../../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Drill Sergeant Privacy" />
    <h1>PRIVACY</h1>
    <p>
      All data you provide through our Alexa Skills (Drill Sergeant, Dr Sugar) gets stored on our server together with a userId. We don't save any personal information and advice you not to enter any personal information. We may process this data anonymously to improve the user experience for our products. You can request the deletion of your data anytime at chris@easysolutions.cc or just by saying "Delete all data" (after "Alexa, open Drill Sergeant") and confirm the dialog with a "Yes"</p>
      <h2 id="de">DATENSCHUTZ</h2>
      <p>
        Alle Daten die Sie durch den Alexa Skill (Drill Sergeant, Dr. Sugar ... ) zur Verfügung stellen werden auf unserem Server zusammen mit einer temporären userId gespeichert. Wir speichern keine persönliche Information und bitten Sie eine solche auch nicht mit Hilfe eines Skills zu speichern. Wir verwenden die Daten anonym um die Userexperience zu verbessern. Wenn Sie Ihre Daten löschen möchten können Sie mich unter chris@easysolutions.cc erreichen. Oder Sie sagen im Skill (nach "Alexa, öffne Drill Sergeant") "Lösche alle Daten" und bestätigen daraufhin mit "Ja".
      </p>

  </Layout>
)

export default IndexPage